import React, { FC } from 'react';
import { PageLayout, OurPeopleDetail, CTALink } from 'components';
import { graphql } from 'gatsby';
import { SeoType } from '../pages/index';

interface peopleListProps {
  data: {
    OurPeoplePage: {
      title: string;
      description: string;
      callToAction: {
        title: string;
        link: string;
      };
      quotation: {
        content: string;
      };
      seo: SeoType;
      peopleList: {
        id: number;
        name: string;
        description: string;
        image: {
          publicURL: string;
          extension: string;
          childImageSharp: {
            fluid: {
              base64: string;
              aspectRatio: number;
              sizes: string;
              src: string;
              srcSet: string;
            };
          };
        };
        iconName: string;
        peopleAccordion: {
          id: number;
          title: string;
          description: string;
        }[];
      }[];
    };
  };
}

const OurPeople: FC<peopleListProps> = ({ data }) => {
  const { OurPeoplePage } = data;
  const { title, seo, peopleList } = OurPeoplePage;
  const quotation = OurPeoplePage.quotation.content;
  const PeopleList = peopleList.sort((a: any, b: any) => {
    return a.id - b.id;
  });
  return (
    <PageLayout
      title={OurPeoplePage.title}
      quotation={quotation}
      metaTitle={seo?.metaTitle ?? title}
      description={seo?.metaDescription}
      image={seo?.shareImage?.publicURL}
      className="container"
    >
      <div className="our-people-page">
        <h4 className="page-title"> &gt; {title}</h4>
        <div className="page-description">
          <p>{OurPeoplePage.description}</p>
        </div>
        <div className="people-lists">
          {PeopleList.map((people, i) => {
            return (
              <OurPeopleDetail
                people={people}
                key={`peoplelist${people.id}`}
                index={i}
              />
            );
          })}
        </div>
        <CTALink to={OurPeoplePage.callToAction.link}>
          {OurPeoplePage.callToAction.title}
        </CTALink>
      </div>
    </PageLayout>
  );
};

export default OurPeople;

export const query = graphql`
  query OurPeoplePageQuery {
    OurPeoplePage: strapiOurPeople {
      title
      description
      callToAction {
        link
        title
      }
      quotation {
        content
      }
      seo {
        metaDescription
        metaTitle
        shareImage {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 400, height: 400)
          }
        }
      }
      peopleList {
        id
        name
        description
        image {
          publicURL
          extension
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        iconName
        peopleAccordion {
          description
          id
          title
        }
      }
    }
  }
`;
